import React, { FunctionComponent, useState } from 'react';
import { navigate } from 'gatsby';
import Container from '@material-ui/core/Container';
import Button from '@material-ui/core/Button';
import colors from '../../themes/main-colors';
import ViewAccount from '../../static/viewAccount.svg';
import PayBill from '../../static/payBill.svg';
import PowerSettings from '../../static/startStopMove.svg';
import ReportOutage from '../../static/reportOutage.svg';
import { useTranslation } from '../../hooks/useTranslation';
import ROUTES from '../../routes';

import TEST_IDS from '../../constants/test_ids';
import useAuth from '../../hooks/useAuth';
import SignInModal from '../sign-in-form/SignInModal';

interface Props {
  setShowHeroSignInSection: React.Dispatch<React.SetStateAction<boolean>>;
}

export const ShortcutsBar: FunctionComponent<Props> = props => {
  const { setShowHeroSignInSection } = props;
  const { t } = useTranslation();
  const { isAuthenticated } = useAuth();
  const [isLoginModalVisible, setIsLoginModalVisible] = useState<boolean>(
    false,
  );
  const [redirectUrl, setRedirectUrl] = useState<string>(ROUTES.HOME);
  const handleExistingOrReturningUserClick = (
    e: React.MouseEvent<HTMLElement>,
    path: string,
  ) => {
    e.preventDefault();

    if (isAuthenticated) {
      void navigate(path);
    } else {
      setShowHeroSignInSection(false);
      setIsLoginModalVisible(true);
    }
  };

  const handleClose = () => {
    setShowHeroSignInSection(true);
    setIsLoginModalVisible(false);
  };
  return (
    <>
      <style>
        {`
            .shortcuts-bar {
              width: 100%;
              height: 115px;
              background: ${colors.white};
              box-shadow: 0 2px 10px ${colors.lightGray1};
              z-index: 2;
            }
            .shortcuts-bar .container {
              display: flex;
              flex-wrap: wrap;
              justify-content: space-between;
              align-items: center;
            }
            .shortcuts-bar .container .item {
              flex: 1;
              height: 115px;
              border: 2px solid ${colors.buttonHoverSecondary};
              border-width: 0 1px 0 1px;
              border-radius: 0;
              text-align: center;
              display: block;
            }
            .shortcuts-bar .container .item:hover {
              background: #EAF5f8;
              background: linear-gradient(to top,  #EAF5f8 0%,#ffffff 100%);
            }

            .shortcuts-bar .container .item h3 {
              color: ${colors.noirBlur};
              margin: 0;
              font-size: 50px;
              font-weight: 100;
              height: 60px;
            }
            .shortcuts-bar .container .item h4 {
              color: ${colors.noirBlur};
              margin: 5px 0;
              font-size: 14px;
              line-height: 18px;
            }
            .shortcuts-bar .container .item h3 .a {
              stroke: ${colors.noirBlur};
            }

            @media only screen and (max-width: 800px) {
              .shortcuts-bar {
                height: auto;
              }
              .shortcuts-bar .container .item {
                min-width: 50%;
                border-width: 0 1px 1px 0;
              }
              .shortcuts-bar .container .item:nth-child(even) {
                min-width: 50%;
                border-width: 0 0 1px 0;
              }
            }

          `}
      </style>
      <div className="shortcuts-bar" data-testid={TEST_IDS.SHORTCUTS_BAR}>
        <Container className="container">
          <Button
            className="item"
            onClick={async (e: any) => {
              e.preventDefault();
              await navigate(ROUTES.OUTAGES);
            }}
            href={ROUTES.OUTAGES}
            id="sbc-4"
          >
            <h3>
              <ReportOutage fontSize="inherit" />
            </h3>
            <h4>{t('GET_OUTAGE_INFO')}</h4>
          </Button>
          <Button
            className="item"
            onClick={async (e: any) => {
              e.preventDefault();
              handleExistingOrReturningUserClick(e, ROUTES.ACCOUNT);
              setRedirectUrl(ROUTES.ACCOUNT);
            }}
            href={ROUTES.ACCOUNT}
            id="scb-1"
          >
            <h3>
              <ViewAccount fontSize="inherit" />
            </h3>
            <h4>{t('VIEW_ACCOUNT')}</h4>
          </Button>
          <Button
            className="item"
            onClick={async (e: any) => {
              e.preventDefault();
              handleExistingOrReturningUserClick(e, ROUTES.PAYMENT);
              setRedirectUrl(ROUTES.PAYMENT);
            }}
            href={ROUTES.PAYMENT}
            id="scb-2"
          >
            <h3>
              <PayBill fontSize="inherit" />
            </h3>
            <h4>{t('PAY_BILL')}</h4>
          </Button>
          <Button
            className="item"
            onClick={async (e: any) => {
              e.preventDefault();
              await navigate(ROUTES.START_STOP_MOVE);
            }}
            href={ROUTES.START_STOP_MOVE}
            id="scb-3"
          >
            <h3>
              <PowerSettings fontSize="inherit" />
            </h3>
            <h4>{t('START_STOP_OR_MOVE')}</h4>
          </Button>
        </Container>
      </div>
      {!isAuthenticated && (
        <SignInModal
          isOpen={isLoginModalVisible}
          redirectUrl={redirectUrl}
          handleClose={handleClose}
        />
      )}
    </>
  );
};
